<template>
  <div>
    <page-title
      heading="Mergeport"
      subheading="Mergeport"
      :icon="icon"
    ></page-title>
    <div class="app-main__inner">
      <MergeportComponent />
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import MergeportComponent from "@/components/settings/moduleSettings/MergeportComponent";

export default {
  name: "Mergeport",
  components: { PageTitle, MergeportComponent },
  data() {
    return {
      icon: "pe-7s-plane icon-gradient bg-tempting-azure",
    };
  },
};
</script>
