<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-row>
            <v-col cols="12" class="text-center py-16">
              <v-img
                height="90"
                src="@/assets/images/Mergeport_Logo.svg"
                contain
                style="margin-top: 10px; margin-bottom: 10px"
              ></v-img>
            </v-col>
          </v-row>

          <v-toolbar dark color="primary" flat>
            <v-toolbar-title> Mergeport API </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-text-field
              v-model="apiKey"
              :disabled="loading"
              outlined
              dense
              :label="$t('settings.lang_apiKey')"
            ></v-text-field>
            <v-checkbox
              v-model="autoAcceptAllOrders"
              :disabled="loading"
              outlined
              dense
              :label="$t('settings.lang_autoAcceptAllOrders')"
            ></v-checkbox>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :disabled="loading"
              :loading="loading"
              @click="saveSettings"
            >
              {{ $t("generic.lang_save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";

export default {
  name: "MergeportComponent",

  data() {
    return {
      loading: false,
      autoAcceptAllOrders: false,
      apiKey: "",
    };
  },
  mounted() {
    this.getInfos();
  },
  methods: {
    getInfos() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.MERGEPORT.GET)
        .then((res) => {
          if (res.data.status === "success") {
            this.apiKey = res.data.mergeport_API_key;
            this.autoAcceptAllOrders = res.data.mergeport_autoAcceptAllOrders;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveSettings() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.MERGEPORT.UPDATE, {
          mergeport_API_key: this.apiKey,
          mergeport_autoAcceptAllOrders: this.autoAcceptAllOrders,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
